import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {ErrorCode} from '../../../../projects/admin-query/src/app/core/api/generated/msa-duty-service';

export const ErrorCodeTextMap: {[code in ErrorCode]: TranslationString} = {
  [ErrorCode.CorruptedDocument]: 'i18n.document.error.corruptedDocument',
  [ErrorCode.DateNotInFuture]: 'i18n.common.error.dateMustBeInFuture',
  [ErrorCode.DatePrecedence]: 'i18n.common.error.datePrecedence',
  [ErrorCode.DocumentTypeNotSupported]: 'i18n.document.error.documentTypeNotSupported',
  [ErrorCode.DateEndBeforeStart]: 'i18n.common.error.dateEndBeforeStart',
  [ErrorCode.DocumentsRequired]: 'i18n.document.error.documentsRequired',
  [ErrorCode.DutyFinished]: 'i18n.duties.error.finished',
  [ErrorCode.DutyHasStarted]: 'i18n.duties.error.dutyHasStarted',
  [ErrorCode.DutyMustNotHaveActiveRequest]: 'i18n.shift.error.dutyMustNotHaveActiveRequest', // dvs only
  [ErrorCode.DutyNotInFuture]: 'i18n.duties.error.dutyNotInFuture',
  [ErrorCode.DutyTooFarInFuture]: 'i18n.duties.error.dutyTooFarInFuture',
  [ErrorCode.DateNotInDutyRange]: 'i18n.leave.error.dateNotInDutyRange',
  [ErrorCode.DutyReplacementIncomplete]: 'i18n.duty-relocation-form.duty-replacement.error.dutyReplacementIncomplete',
  [ErrorCode.DutyReplacementNotAllowed]: 'i18n.duty-relocation-form.duty-replacement.error.dutyReplacementNotAllowed',
  [ErrorCode.ExternalServiceTimeout]: 'i18n.common.error.externalServices.externalServiceTimeout',
  [ErrorCode.FileSizeTooBig]: 'i18n.document.error.fileSizeTooBig',
  [ErrorCode.General]: 'i18n.common.error.generic',
  [ErrorCode.InvalidNumberOfDates]: 'i18n.common.error.invalidNumberOfDates',
  [ErrorCode.LanguageRequired]: 'i18n.shift.error.languageRequired',
  [ErrorCode.LanguageNotAllowed]: 'i18n.shift.error.languageNotAllowed',
  [ErrorCode.LeaveRequestNotAllowed]: 'i18n.leave.error.requestNotAllowed',
  [ErrorCode.MaxDocumentSizeReached]: 'i18n.document.error.maxDocumentSizeReached',
  [ErrorCode.MaxNumberOfDocumentsExceeded]: 'i18n.document.error.maxNumberOfDocumentsExceeded',
  [ErrorCode.MinimalDuration]: 'i18n.common.error.minimalDuration',
  [ErrorCode.NoAgreement]: 'i18n.common.error.noAgreement',
  [ErrorCode.NoDocForMedicalReason]: 'i18n.document.error.noDocForMedicalReason',
  [ErrorCode.NonValidDutyState]: 'i18n.duties.error.nonValidDutyState',
  [ErrorCode.NonValidRequestState]: 'i18n.common.error.nonValidRequestState',
  [ErrorCode.PreventedDateNotInFuture]: 'i18n.common.error.preventedDateNotInFuture',
  [ErrorCode.RequestDetailTypeNotMatch]: 'i18n.common.error.requestDetailTypeNotMatch',
  [ErrorCode.RequestTypeNotAllowed]: 'i18n.common.error.requestTypeNotAllowed',
  [ErrorCode.SameYear]: 'i18n.common.error.sameYear',
  [ErrorCode.StorageServiceError]: 'i18n.common.error.externalServices.storageServiceError',
  [ErrorCode.TooFewCharacters]: 'i18n.common.error.tooFewCharacters',
  [ErrorCode.TooManyCharacters]: 'i18n.common.error.tooManyCharacters',
  [ErrorCode.UnableToSaveDocument]: 'i18n.document.error.unableToSaveDocument',
  [ErrorCode.UnknownCodeValue]: 'i18n.common.error.unknownCodeValue',
  [ErrorCode.DutyTypeNotAllowed]: 'i18n.shift.info.dvsNotAllowed',
  [ErrorCode.ExternalServiceError]: 'i18n.common.error.generic'
};
